import React, {useRef, useState} from "react";
import { GoogleMap, Autocomplete, Polyline,  LoadScript, Marker, DistanceMatrixService } from "@react-google-maps/api";
import './Map.css'


const service = new DistanceMatrixService()
/*https://react-google-maps-api-docs.netlify.app/#section-introduction*/
const propArray = ["places"]

export const Map = ({ isTimeSelected, calculateDistance, formState, autocompleteValue, handleAutocompleteChange, onLoad, onPlaceChanged, center, mapContainerStyle }) => {

  return (
    <LoadScript
    googleMapsApiKey="AIzaSyDtsQvwwRJRxZkZSuqnSinXdhgGWB-dTjQ"
    libraries={propArray}
    >
      <Autocomplete
        onLoad={onLoad}
        onPlaceChanged={onPlaceChanged}
        >
          <input
          type="text"
          placeholder="Address"
          value={autocompleteValue}
          onChange={handleAutocompleteChange}
          className={isTimeSelected ? 'mapInput timeSelected' : 'mapInput noTimeSelected'}
          />
        </Autocomplete>
      <GoogleMap
      id="searchbox-example"
      mapContainerStyle={mapContainerStyle}
      zoom={14}
      center={center}
      >
      <Marker position={center}/>
      </GoogleMap>
      <DistanceMatrixService options={{
        destinations: [`${!formState ? '709 Atlas Ave, Madison, WI' : formState.address + ', ' + formState.city + ', ' + formState.state}`],
        origins: ['709 Atlas Ave, Madison, WI'],
        travelMode: 'DRIVING'
      }}
      callback={calculateDistance}/>
    </LoadScript>
  )
}