import React, { useEffect, useRef, useState } from 'react'
import { NavBarLink } from '../Home/NavBarLink/NavBarLink'
import { Link } from 'react-router-dom'
import './NavBar.css'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faFacebook, faInstagram, faTwitter } from '@fortawesome/free-brands-svg-icons'

import logo from '../../images/curd-logo.png'

export const NavBar = ( { routes, isIntersecting } ) => {
    const [menuOpen, setMenuOpen] = useState(false)

    const menuBtn = useRef()

    const hamburgerAnimation = () => {
        if(!menuOpen) {
            menuBtn.current.classList.add('open')
            setMenuOpen(true)
        } else {
            menuBtn.current.classList.remove('open')
            setMenuOpen(false)
        }
    }

    const navBarRef = useRef()

    const logoRef = useRef()

    const linkRef = useRef()

    const h2Ref = useRef()

    useEffect(() => {
        if (isIntersecting) {
            navBarRef.current.style.width = '100%';
            logoRef.current.style.display = 'block'
            linkRef.current.style.display = 'block'
            h2Ref.current.style.display = 'block';
            console.log(isIntersecting)
        } else {
            navBarRef.current.style.width = '0';
            logoRef.current.style.display = 'none'
            linkRef.current.style.display = 'none'
            h2Ref.current.style.display = 'none'
            console.log(isIntersecting)
        }
    })

    return (
        <div className='navBarContainer' ref={navBarRef}> 
            <div className='findUsContainer'>
                <Link to='/bookus' ref={linkRef} className='findUsLink'>Find us!</Link>
            </div>
            <Link className='logoContainer' to='/' ><img src={logo} id='homeLogo' ref={logoRef}></img></Link>
            <h2 ref={h2Ref}>Award winning Wisconsin fried cheese curds since 2014</h2>
                <nav className={menuOpen ? 'NavBar' : 'retracted'} >
                    <div className='socialMedias'>
                        <a target='_blank' href='https://www.facebook.com/CurdGirl/'><FontAwesomeIcon icon={faFacebook} className='icon' /></a>
                        <a target='_blank' href='https://www.instagram.com/curdgirl/?hl=en'><FontAwesomeIcon icon={faInstagram}  className='icon' /></a>
                    </div>
                    {routes.map((route, index) => <NavBarLink key={index} route={route} onClick={hamburgerAnimation}/>)}
                </nav>
                <div className='burgerBtn'>
                    <div ref={menuBtn} className='menuBtn' onClick={hamburgerAnimation}>
                        <div className='menuBtnBurger'></div>
                    </div>
                </div>
        </div>

    )
}

