import './Contact.css'
import { ContactForm } from '../ContactForm/ContactForm'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faFacebook, faInstagram, faTwitter } from '@fortawesome/free-brands-svg-icons'

export const Contact = () => {
    return (
        <div className='contact'>
            <h1>Contact Us!</h1>
            <ContactForm/>
            <div className='footer'>
                <div className='socialMedias'>
                    <a target='_blank' href='https://www.facebook.com/CurdGirl/'><FontAwesomeIcon icon={faFacebook} className='icon' /></a>
                    <a target='_blank' href='https://www.instagram.com/curdgirl/?hl=en'><FontAwesomeIcon icon={faInstagram}  className='icon' /></a>
                </div>
                <p>Email: curdgirl@gmail.com</p>
                <p>©2022 Curd Girl LLC</p>
                <br/><br/>
            </div>
        </div>
    )
}