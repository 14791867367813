import React, { useState, useEffect } from 'react'
import { GoogleMap, Polyline, Marker } from "@react-google-maps/api";
import axios from 'axios';

const CalendarEventMap = (props) => {

    const [latLng, setlatLng] = useState({})

    const getLatLng = async () => {
        const regex = /\s/g
        const parsedAddress = props.place.replace(regex, '+')
        console.log(parsedAddress)
        const response = await axios.get(`https://maps.googleapis.com/maps/api/geocode/json?address=${parsedAddress}&key=AIzaSyDtsQvwwRJRxZkZSuqnSinXdhgGWB-dTjQ`)
        console.log(response.data.results[0].geometry.location)
        setlatLng(response.data.results[0].geometry.location)
    }

    useEffect(() => {
        getLatLng()
    }, [])
    

    return (
        <GoogleMap
        id="searchbox-example"
        mapContainerStyle={{width: '100%', height: '30vh'}}
        zoom={14}
        center={latLng}
        >
            <Marker position={latLng}/>
        </GoogleMap>
    )
}

export default CalendarEventMap