import './Home.css'
import { SlideShow } from '../SlideShow/SlideShow'
import event from '../../images/Alejandra grad on capitol steps 1.jpg'
import wedding from '../../images/ashleyjake_W18_final_02183 2.jpeg'
import festival from '../../images/CurdGirlTasteofMadison.jpg'
import more from '../../images/CurdGirl CurdLandia T-shirt.jpg'
import React, { useRef, useEffect, useState } from 'react'
import { Link } from 'react-router-dom'
import { Booking } from '../Booking/Booking'
import logo from '../../images/Curd Girl-50_White-1,Curd Girl-50_White-2/Curd Girl-50_White-1.png'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faFacebook, faInstagram, faTwitter } from '@fortawesome/free-brands-svg-icons'

export const Home = (props) => {

    const homeRef = useRef()

    const logoRef = useRef()

    const homeOptions = {
        rootMargin: '-500px 0px 0px 0px'
    }

    const homeObserver = new IntersectionObserver((entries, homeObserver) => {
        entries.forEach(entry => {
            if (!entry.isIntersecting) {
                props.setIsIntersecting(true)
                //console.log('intersected')
            } else {
                props.setIsIntersecting(false)
                //console.log('not intersected')
            }
        })
    }, homeOptions)

    useEffect(() => {
        homeObserver.observe(homeRef.current)
        return () => {
            homeObserver.unobserve(homeRef.current)
        }
    }, [])
    
    useEffect(() => {
        if (props.isIntersecting) {
            logoRef.current.style.height = '70px';
            logoRef.current.style.position = 'fixed';
            logoRef.current.style.margin = '0';
            logoRef.current.style.top = '-0px';
            logoRef.current.style.opacity = '0';
        } else {
            logoRef.current.style.position = 'absolute';
            logoRef.current.style.height = '400px';
            logoRef.current.style.margin = '5%';
            logoRef.current.style.top = '-5vh';
            logoRef.current.style.left = '0'
            logoRef.current.style.opacity = '1';
        }
    })

    return (
        <div className='home' >
            <div className='headerRef'ref={homeRef}/>
            <div className='header'>
                <div className='homeLogoContainer' >
                    <Link to='/'><img src={logo} id='homeLogo' ref={logoRef}></img></Link>
                </div>
            </div>
            <SlideShow pictures={props.pictures} />
            <div className='main' >   
                <div className='topics'>
                    <div className='weddings place'>
                        <img src={wedding} />
                        <p><section className='arrow-up'></section><h4>Weddings</h4>Extravagantly fancy or rustic chic. Lavishly large or just family & friends. We are a uniquely Wisconsin addition to any wedding menu whether as a cocktail hour appetizer, a companion to the main event, or a late night snack.<br/><Link to='/bookus' className='placeBtn'>Check Availability</Link></p>
                    </div>
                    <div className='events place'>
                        <img src={event}/>
                        <p><section className='arrow-up'></section><h4>Private Events</h4>From company picnics & employee appreciation lunches to graduations, birthdays, memorials, fundraisers, and community events, we have brought our smiles and award winning curds to them all. From parties of 100-1,000 and more, Curd Girl is an unforgettable experience.<br/><Link to='/bookus' className='placeBtn'>Inquire</Link></p>
                    </div>
                    <div className='festivals place'>
                        <img src={festival} />
                        <p><section className='arrow-up'></section><h4>Festivals</h4>Based in Madison and a perennial fan favorite at the many neighborhood and civic festivals in and around Wisconsin's Capitol City, Curd Girl travels far and wide sharing the curd! If you think Curd Girl would be a good fit for your festival and to see where we'll be this season follow the link below.<br/><Link to='/bookus' className='placeBtn'>Upcoming Events</Link></p>
                    </div>
                    <div className='description place'>
                        <img src={more} />  
                        <p><section className='arrow-up'></section><h4>Whey Cool Store</h4>Based in Madison and a perennial fan favorite at the many neighborhood and civic festivals in and around Wisconsin's Capitol City, Curd Girl travels far and wide sharing the curd! If you think Curd Girl would be a good fit for your festival and to see where we'll be this season follow the link below. Coming Soon!</p>
                    </div>
                </div>
                <div className='quote'>
                    <h5>Curd Girl is the creation of two best friends from the cheese making community of Monroe, Wisconsin, carried on by the Butcher-Salazar sisters- Bella, Hazel, and Alejandra. Raised on the freshest, best-quality cheese, we were inspired to share the joy that is hands-down the best deep fried cheese in the country. To accompany this cheesy fried goodness, we’ve created home-made gourmet sauces as well as refreshing drinks to wash them down. All of our creations are made with lots of love, and we always choose local, organic ingredients whenever possible. Plain and simple, Curd Girl is a celebration of Wisconsin, and we can’t wait to celebrate with you!</h5>
                </div>
            </div>
            <div className='footer'>
                <div className='socialMedias'>
                    <a target='_blank' href='https://www.facebook.com/CurdGirl/'><FontAwesomeIcon icon={faFacebook} className='icon' /></a>
                    <a target='_blank' href='https://www.instagram.com/curdgirl/?hl=en'><FontAwesomeIcon icon={faInstagram}  className='icon' /></a>
                </div>
                <p>Email: curdgirl@gmail.com</p>
                <p>©2022 Curd Girl LLC</p>
                <br/><br/>
            </div>
        </div>
    )
}