import React, { useEffect, useRef } from 'react'
import './Booking.css'
import { Calendar } from '../Calendar/Calendar'
import { BookingForm } from '../BookingForm/BookingForm'
import { useState } from 'react'
import { TimePicker } from '../TimePicker/TimePicker'
import { Map } from '../Map/Map'
import axios from 'axios'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faFacebook, faInstagram, faTwitter } from '@fortawesome/free-brands-svg-icons'


const mapContainerStyle = {
  height: "100%",
  width: '100%'
}

export const Booking = () => {

  /* Form State */
  const initialFormState = {
    email: '',
    name: '',
    phone: '',
    date: '',
    startTime: '',
    endTime: '',
    guests: '',
    venue: '',
    address: '',
    state: '',
    city: '',
    zip: '',
    type: '',
    message: '',
    length: ''
  }

  const [when, setWhen] = useState({
    date: '',
    time: '',
  })

  const [formState, setFormState] = useState(initialFormState)

  /* Input Change */
  const updateInput = event => {
    let newFormState = {...formState}
    newFormState[event.target.id] = event.target.value
    setFormState(newFormState)
    console.log(event.target.id)
    console.log(formState[event.target.id])
  }

  /* Map */

  const [distance, setDistance] = useState('0')

  const calculateDistance = response => {
    const newDistance = String(Math.floor(parseInt(response.rows[0].elements[0].distance.text) * 0.621371)) + 'm'
    setDistance(newDistance)
  }

  const [autocompleteValue, setAutocompleteValue] = useState()

  const handleAutocompleteChange = event => {
    setAutocompleteValue(event.target.value)
  }
  
  const [location, setLocation] = useState({
    address: '',
    city: '',
    state: '',
    zip: ''
  })

  const handleLocationChange = location => {
    let newFormState = {...formState}
    newFormState.address = location.address
    newFormState.city = location.city
    newFormState.state = location.state
    newFormState.zip = location.zip
    newFormState.venue = location.venue
    setFormState(newFormState)
    console.log(formState)
  }

  const [autocomplete, setAutocomplete] = useState(null)

  const [center, setCenter] = useState({lat: 43.073867, lng: -89.383857})

  const onLoad = (autocomplete) => {
    setAutocomplete(autocomplete)
  }

  const onPlaceChanged = () => {
    if (autocomplete !== null) {
      //console.log(autocomplete.getPlace())
      const locationCords = autocomplete.getPlace().geometry.location;
      console.log(locationCords.lng())
      console.log(locationCords.lat())
      setCenter({lng: locationCords.lng(), lat: locationCords.lat()})
      const addressComponents = autocomplete.getPlace().address_components
      const arrayOfInputs = ['street_number', 'route', 'locality', 'administrative_area_level_1', 'postal_code']
      const locationInfo = addressComponents.filter((info) => {
        if (arrayOfInputs.includes(info.types[0])) {
          return info
        }
      })
      console.log(autocomplete.getPlace().name)
      const location = {
        address: '',
        city: '',
        state: '',
        zip: ''
      }
      locationInfo.forEach(info => {
        if (info.types[0] === 'street_number' || info.types[0] === 'route') {
          location.address += (info.short_name + ' ') 
        } else if (info.types[0] === 'locality') {
          location.city += info.short_name
        } else if (info.types[0] === 'administrative_area_level_1') {
          location.state += info.short_name
        } else if (info.types[0] === 'postal_code') {
          location.zip += info.short_name
        }
      })
      const name = autocomplete.getPlace().name + ' '
      console.log(location.address)
      console.log(name === location.address)
      if (name !== location.address) {
        location.venue = name
      } else {
        location.venue = ''
      }
      handleLocationChange(location)
      console.log(location)
    } else {
      console.log('Autocomplete is not loaded yet!')
    }
  }

  const [autocomplete2, setAutocomplete2] = useState(null)

  const onLoad2 = (autocomplete2) => {
    setAutocomplete2(autocomplete2)
  }

  const onPlaceChanged2 = () => {
    if (autocomplete2 !== null) {
      console.log(autocomplete.getPlace())
      const locationCords = autocomplete2.getPlace().geometry.location;
      console.log(locationCords.lng())
      console.log(locationCords.lat())
      setCenter({lng: locationCords.lng(), lat: locationCords.lat()})
      const addressComponents = autocomplete2.getPlace().address_components
      const arrayOfInputs = ['street_number', 'route', 'locality', 'administrative_area_level_1', 'postal_code']
      const locationInfo = addressComponents.filter((info) => {
        if (arrayOfInputs.includes(info.types[0])) {
          return info
        }
      })
      console.log(autocomplete2.getPlace().name)
      const location = {
        address: '',
        city: '',
        state: '',
        zip: ''
      }
      locationInfo.forEach(info => {
        if (info.types[0] === 'street_number' || info.types[0] === 'route') {
          location.address += (info.short_name + ' ') 
        } else if (info.types[0] === 'locality') {
          location.city += info.short_name
        } else if (info.types[0] === 'administrative_area_level_1') {
          location.state += info.short_name
        } else if (info.types[0] === 'postal_code') {
          location.zip += info.short_name
        }
      })
      const name = autocomplete2.getPlace().name + ' '
      console.log(location.address)
      console.log(name === location.address)
      if (name !== location.address) {
        location.venue = name
      } else {
        location.venue = ''
      }
      handleLocationChange(location)
      console.log(location)
    } else {
      console.log('Autocomplete is not loaded yet!')
    }
  }

  /* Form Submitting */
  const formId = '6AxxQYLo'
  const formSparkUrl = 'https://submit-form.com/' + formId
  const recaptchaRef = useRef()
  const recaptchaKey = '6Lf3QAsfAAAAAMnVL7KtAlmqMrDXx2EwT7v53ZdE'
  const [recaptchaToken, setRecaptchaToken] = useState('')
  const postSubmit = async () => {
      const payload = {
          ...formState,
          'g-recaptcha-response': recaptchaToken
      }

      try {
          const result = await axios.post(formSparkUrl, payload)
          console.log(result)
          setFormState(initialFormState)
          recaptchaRef.current.reset()
      } catch(error) {
          console.log(error)
      }
  }
  const handleSubmit = async (event) => {
    updateCost()
    event.preventDefault()
    setSubmitting(true)
    await postSubmit()
    setSubmitting(false)
    setSubmitted(true)
  }

  const [submitting, setSubmitting] = useState(false)
  const [submitted, setSubmitted] = useState(false)

  const updateRecaptchaToken = (token) => {
      setRecaptchaToken(token)
  }

  useEffect(() => {
    setAutocompleteValue(formState.address)
  }, [formState])

  /* Test */

  const [isDaySelected, setIsDaySelected] = useState(false)

  const handleDaySelect = () => {
    if (formState.date !== '') {
      setIsDaySelected(true)
    }
  }

  const [isPlaceSelected, setIsPlaceSelected] = useState(false)

  const handlePlaceSelect = () => {
    if (formState.address !== '') {
      setIsPlaceSelected(true)
    }
  }

  const [estimatedCost, setEstimatedCost] = useState(0)

  const updateCost = () => {
    let newCost = 0.0;
    newCost = newCost + (parseFloat(formState.guests) * 8) * 0.18
    newCost = newCost + (parseFloat(distance) > 15 ? parseFloat(distance) * 3 : 0)
    newCost = newCost + (parseFloat(formState.length) > 1.5 ? parseFloat(formState.length - 1.5) * 90 : 0)
    newCost = newCost * 1.05
    setEstimatedCost(Math.floor(newCost))
    console.log(newCost)
    /*- number of guests * 8 * (.18)
- miles * 3 (over 15 miles)
- over 90, 1.50 min
- multipe 1.55*/
  }

  const updateLength = () => {
    const startTime = formState.startTime.split(':').join('.')
    let newStartTime = 0
    if (startTime.includes('P')) {
      newStartTime = startTime.replace('A', '').replace('M', '').replace('P', '')
      newStartTime = parseFloat(newStartTime) + 12
    } else {
      newStartTime = parseFloat(startTime.replace('A', '').replace('M', '').replace('P', ''))
    }
    const endTime = formState.endTime.split(':').join('.')
    let newEndTime = 0
    if (endTime.includes('P')) {
      newEndTime = endTime.replace('A', '').replace('M', '').replace('P', '')
      newEndTime = parseFloat(newEndTime) + 12
    } else {
      newEndTime = parseFloat(endTime.replace('A', '').replace('M', '').replace('P', ''))
    }
    let length = 0;
    if (overnight) {
      length += 25 - newStartTime
      length += newEndTime - 1
    } else {
      length = newEndTime - newStartTime
    }
    const newFormState = {...formState}
    newFormState.length = length
    setFormState(newFormState)
  }

  const [overnight, setOvernight] = useState(false)

  const checkOverNight = () => {
    if (formState.endTime.includes('A') && formState.startTime.includes('P')) {
      setOvernight(true)
    } else {
      setOvernight(false)
    }
    console.log(overnight)
  }

  const handleGoBackClickMap = () => {
    const newFormState = {...formState}
    newFormState.date = ''
    newFormState.address = ''
    setFormState(newFormState)
    setIsPlaceSelected(false)
    setIsDaySelected(false)
  }

  const handleGoBackClickSubmitted = () => {
    setFormState(initialFormState)
    setIsDaySelected(false)
    setIsPlaceSelected(false)
    setSubmitted(false)
  }

  useEffect(() => {
    handleDaySelect()
    handlePlaceSelect()
  }, [formState.date, formState.address])

  useEffect(() => {
    updateLength()
    checkOverNight()
    console.log(formState)
  }, [formState.startTime, formState.endTime])

  useEffect(() => {
    updateLength()
  }, [overnight])

  return (
    <div className='booking'>
      <h1>Check back next year!</h1>
      <main>
        <h3>Curd Calendar</h3>
        <div className='calendarContainer'>
          <Calendar updateInput={updateInput} calculateDistance={calculateDistance} formState={formState} onLoad={onLoad} handleAutocompleteChange={handleAutocompleteChange} autocompleteValue={autocompleteValue} onPlaceChanged={onPlaceChanged} center={center} mapContainerStyle={mapContainerStyle}/>
        </div>
        <div className='mapContainer' style={isDaySelected ? {display: 'block'} : {display: 'none'}}>
          <div className='mapAndForm'>
            <BookingForm overnight={overnight} onLoad2={onLoad} onPlaceChanged2={onPlaceChanged} setFormState={setFormState} distance={distance} calculateDistance={calculateDistance} formState={formState} handleAutocompleteChange={handleAutocompleteChange} autocompleteValue={autocompleteValue} onLoad={onLoad2} onPlaceChanged={onPlaceChanged2} center={center} recaptchaKey={recaptchaKey} recaptchaRef={recaptchaRef} location={location} handleSubmit={handleSubmit} updateRecaptchaToken={updateRecaptchaToken} time={when} updateInput={updateInput} submitted={submitted} postSubmit={postSubmit}/>
            <div className='map'>
              <Map calculateDistance={calculateDistance} formState={formState} onLoad={onLoad} handleAutocompleteChange={handleAutocompleteChange} autocompleteValue={autocompleteValue} onPlaceChanged={onPlaceChanged} center={center} mapContainerStyle={mapContainerStyle}/>
            </div>
          </div>
          <button className='backBtnContainer' onClick={handleGoBackClickMap}>Go back</button>
        </div>
        <div className='submittedContainer' style={submitted ? {display: 'block'}: {display: 'none'}}>
          <h3>Thanks for submitting, your preliminary estimated cost is: ${estimatedCost}, if you have any further questions contact us at curdgirl@gmail.com.</h3>
          <button className='backBtnContainer' onClick={handleGoBackClickSubmitted}>Go back</button>
        </div>  
      </main>
      <div className='footer'>
          <div className='socialMedias'>
              <a target='_blank' href='https://www.facebook.com/CurdGirl/'><FontAwesomeIcon icon={faFacebook} className='icon' /></a>
              <a target='_blank' href='https://www.instagram.com/curdgirl/?hl=en'><FontAwesomeIcon icon={faInstagram}  className='icon' /></a>
          </div>
          <p>Email: curdgirl@gmail.com</p>
          <p>©2022 Curd Girl LLC</p>
          <br/><br/>
      </div>
    </div>
    
  )
}
