import React, { useState, useEffect, useRef } from "react";
import './SlideShow.css'
import picture from '../../images/IMG_0067.jpeg'
import picture2 from '../../images/barnwedding.jpg'
import picture3 from '../../images/CurdGirlTasteofMadison.jpg'
import picture4 from '../../images/WeddingCurdGirl2021.jpg'
import picture5 from '../../images/Bellagrad2.jpg'
import picture6 from '../../images/TobaccoDaysCrew.png'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faArrowDown } from "@fortawesome/free-solid-svg-icons";

const delay = 7500;

const pictures = [picture, picture2, picture3, picture4, picture5, picture6]

export const SlideShow = () => {
    const [index, setIndex] = useState(0);

    useEffect(() => {
        const interval = setInterval(() => {
            if (index < pictures.length - 1) {
                setIndex(index + 1);
            } else {
                setIndex(0)
            }
        }, delay);
        return () => clearInterval(interval);
      });

    return (
            <div className="slideshow">
                <img src={picture} style={{display: 'none'}} alt=''></img>
                <img src={picture5} style={{display: 'none'}} alt=''></img>
                <img src={picture2} style={{display: 'none'}} alt=''></img>
                <img src={picture3} style={{display: 'none'}} alt=''></img>
                <img src={picture4} style={{display: 'none'}} alt=''></img>
                <div className="slideshowSlider" style={{backgroundImage: `url(${pictures[index]})`, animation: 'fadeMe 35s ease-in-out', animationIterationCount: 'infinite'}} />
                <div className='arrowContainer'>
                    <FontAwesomeIcon icon={faArrowDown} className='downArrow second-pulse'></FontAwesomeIcon>
                </div>
            </div>
    )
}