import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import './App.css';
import { NavBarContainer } from './containers/NavBarContainer/NavBarContainer'
import { HomeContainer } from './containers/HomeContainer/HomeContainer';
import { ContactContainer } from './containers/ContactContainer/ContactContainer';
import { GalleryContainer } from './containers/GalleryContainer/GalleryContainer';
import { MerchContainer } from './containers/MerchContainer/MerchContainer';
import { BookingContainer } from './containers/BookingContainer/BookingContainer';
import { Animator, ScrollContainer, ScrollPage } from 'react-scroll-motion';
import { useState, useEffect } from 'react';

const App = () => {
  const [isIntersecting, setIsIntersecting] = useState(false) 
  const [isLoaded, setIsLoaded] = useState(false)


  const routes = [{name: 'Home', path:'/', element: <HomeContainer setIsLoaded={setIsLoaded} isLoaded={isLoaded} setIsIntersecting={setIsIntersecting} isIntersecting={isIntersecting}/>},
    {name: 'Booking', path: '/bookus', element: <BookingContainer />},
    /*{name: 'Gallery', path: '/gallery', element: <GalleryContainer />}*/,
    {name: 'Contact', path: '/contact', element: <ContactContainer />},
    /*{name: 'Merch', path: '/merch', element: <MerchContainer />}*/
  ]

  const [popUp, setPopUp] = useState(localStorage.getItem('popUp'))



  useEffect(() =>{
    setIsLoaded(true)
  }, [])

  useEffect(() => {
    setPopUp(true)
  }, [isLoaded])

  /*        <section className='loadPopUp' style={popUp ? {display: 'flex'} : {display: 'none'}}>
  <a href='https://www.channel3000.com/best-of-madison-2022/' target='_blank'>Vote!</a>
  <button onClick={() => {setPopUp(false)}}>x</button>
</section>*/
  return (
      <Router>
        <NavBarContainer routes={routes} setIsIntersecting={setIsIntersecting} isIntersecting={isIntersecting}/>
        <Routes>
          {routes.map((route, index) => <Route key={index} path={route.path} element={route.element}/>)}
        </Routes>
      </Router>
  );
}

export default App;
