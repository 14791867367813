import React, { useEffect, useState } from "react";
import moment from 'moment';
import './Calendar.css'
import Papa from 'papaparse'
import CalendarEventMap from '../CalendarEventMap/CalendarEventMap'

export const Calendar = (props) => {
    const [value, setValue] = useState(moment())
    const [currentDay, setCurrentDay] = useState(moment())
    const [startDay, setStartDay] = useState(value.clone().startOf('month').startOf('week'))
    const [endDay, setEndDay] = useState(value.clone().endOf('month').endOf('week'))
    const day = startDay.clone().subtract(1, 'day')

    const [calendar, setCalendar] = useState([])

    

    const updateCalendar = () => {
        let newCalendar = []
        //console.log(events)
        while( day.isBefore(endDay, 'day')) {
            newCalendar.push(
                Array(7)
                .fill(0)
                .map(() => day.add(1, 'day').clone())
            )
        }
        newCalendar.forEach(week => week.forEach(day => {
            day.events = []
            events.forEach(event => {
                if(event.date === day.format('YYYY-MM-DD').toString()){
                    day.events.push(event)
                    //console.log(day.format('MM-DD-YYYY'))
                    //console.log(day.events)
                }
            })
        }))
        //newCalendar.forEach(week => week.forEach(day => console.log( day.events ? day.events : '')))
        //console.log(value.format('MM/DD/YYYY'))
        setCalendar(newCalendar) 
        //console.log('updated')
    }

    var [response, setResponse] = useState([]);

    //console.log(response)

    const [events, setEvents] = useState([{}])

    function init() {
        Papa.parse('https://docs.google.com/spreadsheets/d/e/2PACX-1vRxWQdSB9OUmhg2fieoCjNHXsIsPYadU9Gtjguza6nI4cjYdrrjcabQsPETL6I3VEJu67IejMeNnyOu/pub?output=csv', {
        download: true,
        header: false,
        complete: function(results) {
            //console.log(results)
            var data = results.data
            setResponse(data)
        }
      })
    }

    const updateEvents = () => {
        const newEvents = response.map(event => {
            return {
                title: event[0],
                date: event[1],
                startTime: event[2],
                endTime: event[3],
                description: event[4],
                location: event[5],
                clicked: false

            }
        })
        setEvents(newEvents)
        //console.log(events)
    }

    const checkAvailability = (day) => {
        if (response.filter(event => {
            if (event.date === day.format('YYYY-MM-DD').toString()){   
                return true
            }
        }).length !== 0 ||  day.isBefore(currentDay.clone().subtract(1, 'day'))) {
            return false
        } else {
            return true
        }
    }

    useEffect(() => { 
        init()
        updateCalendar()
        
    }, [])

    useEffect(() => {
        setStartDay(value.clone().startOf('month').startOf('week'))
        setEndDay(value.clone().endOf('month').endOf('week'))
    }, [value])

    useEffect(() => {
        updateCalendar()
    }, [startDay, endDay, events])

    useEffect(() => {
        updateEvents()
    }, [response])

    useEffect(() => {

    }, [calendar])

    const goBack = () => {
        setValue(value.clone().subtract(1, 'month'))
    }

    const goForward = () => {
        setValue(value.clone().add(1, 'month'))
    }

    const parseDescription = (description) => {
        console.log(description)
        const reg = /\<.*?\>/g
        const linkReg = /(https?:\/\/[^\s]+)/g
        let newDescription = description.replace(reg, '')
        let links = newDescription.split(linkReg)
        links = links.filter((link) =>{
            return link.length > 10 && link.substring(0, 4) === 'http'
        })
        let linksString = links
        links = links.map((link) => {
            return (
                <>
                    <a href={link} target='_blank'>{link}</a>
                    <br/>
                </>
                )
        })
        //console.log(linksString)
        linksString.forEach((link) => {
            //console.log(link)
            newDescription = newDescription.replace(link, ' ')
        })
        //console.log(links)
        //console.log(newDescription)
        return {description: newDescription, links: links}
    }

    return (
        <div className='calendar'>

            <div className='calendarBtns'>
                <input type='button' className='arrow left' onClick={goBack} key={1}/>
                <h3>{value.clone().format('MMMM')}</h3>
                <input type='button' className='arrow right' onClick={goForward} key={2}/>
            </div>
            {calendar.map((week) => (
                <div className='week' key={week}  >
                    {week.map((day) => (
                        <button disabled={!checkAvailability(day)}
                                id='date' 
                                className={(checkAvailability(day) ? 'available day ' : 'unavailable-day ')} 
                                key={day} 
                                value={day.format('YYYY-MM-DD').toString()} 
                                onClick={props.updateInput}
                            >
                            <span className='dayNumber'>{day.format('D')}</span>
                            {(day.events.length > 0 ? day.events.map(event => (
                                <div>
                                    <div key={event} value={event} className='event' style={{left: '0', top: (parseInt(event.startTime.substring(0, 2)) * 3) + '%'}} onClick={() => {
                                        calendar.forEach(week => {
                                            week.forEach(day => {
                                                day.events.forEach(event => {
                                                    event.clicked = false;
                                                })
                                            })
                                        })
                                        event.clicked = true;
                                    }}/>  
                                    <div className='eventDescription'>
                                    {event.title}<br/>
                                    {event.startTime ? (event.startTime.substring(0, 2) > 12 ?
                                    (parseInt(event.startTime.split(':')[0]) - 12) + ':' + event.startTime.split(':')[1] + 'PM-' : event.startTime.split(':')[0] === '12' ? (event.startTime + 'PM-') : (event.startTime + 'AM-')) : ''}
                                    {event.startTime ? (event.endTime.substring(0, 2) > 12 ?
                                    (parseInt(event.endTime.split(':')[0]) - 12) + ':' + event.endTime.split(':')[1] + 'PM' : event.endTime.split(':')[0] === '12' ? (event.endTime + 'PM') : (event.endTime + 'AM')) : ''}
                                    </div>
                                    <div className={event.title.toLowerCase().includes('private') ? 'eventMore hidden' : event.clicked ? 'eventMore show' : 'eventMore hidden'}>
                                        <div className='arrow left' onClick={() => {event.clicked = false}}/>
                                        <h3>{event.title}</h3>
                                        {event.description ? parseDescription(event.description).description : ''}
                                        {event.description ? parseDescription(event.description).links : ''}
                                        <br/>
                                        <p className='locationLink'>{event.location ? 'Location: ' + event.location : ''}</p>
                                        <div className='eventMoreMap'>
                                            {event.location ? <CalendarEventMap place={event.location} /> : ''}
                                        </div>
                                    </div>
                                </div>
                            )) : <div className='noEvent' ></div>)}
                        </button>
                    ))}
                </div>
            ))}
            <div className='calendarHeader'>
                <h4>Hover over events to see a preview or click for more info. <br/> Click on an open day to start booking.</h4>
            </div>
        </div>
    )
}



