import { useEffect, useState } from 'react'
import ReCAPTCHA from 'react-google-recaptcha'
import { Map } from '../Map/Map'
import './BookingForm.css'

export const BookingForm = ({ overnight, setFormState, submitted, distance, calculateDistance, handleAutocompleteChange, autocompleteValue, updateInput, submitting, formState, handleSubmit, updateRecaptchaToken, recaptchaKey, recaptchaRef, onLoad, onPlaceChanged, center}) => {
    
    const [typePlaceholder, setTypePlaceholder] = useState('')
    
    const handleOtherChange = (event) => {
        setTypePlaceholder(event.target.value)
        let newFormState = {...formState}
        newFormState.type = 'Other: ' + event.target.value;
        setFormState(newFormState)
    }
    
    const [hours, setHours] = useState([])
    const [minutes, setMinutes] = useState([])

    const updateTimes = () => {
        const newHours = []
        for(let i = 1; i < 13; i++) {
            newHours.push(i)
        }
        setHours(newHours)
        const newMinutes = []
        for(let i = 15; i < 60; i += 15) {

            newMinutes.push(i)
        }
        setMinutes(newMinutes)
    }

    const initialTimes = {
        startHour: '0',
        startAMPM: 'AM',
        endHour: '0',
        endAMPM: 'AM'
    }

    const [times, setTimes] = useState(initialTimes)

    const handleTimeChange = (event) => {
        let newTimes = {...times}
        newTimes[event.target.id] = event.target.value
        setTimes(newTimes)
    }   

    const updateTimesInForm = () => {
        const newStartTime = times.startHour+ ' ' +  times.startAMPM;
        const newEndTime = times.endHour + ' ' +  times.endAMPM;
        const newFormState = {...formState}
        newFormState.startTime = newStartTime;
        newFormState.endTime = newEndTime;
        setFormState(newFormState)
        console.log(formState)
    }

    const checkTimeMinimum = () => {
        const length = !overnight ? times.endHour - times.startHour - ((times.endMinutes - times.startMinutes) * 0.01) : 3 
        console.log(length)
        if (length < 1.5) {
            let newTimes = {...times}
            newTimes.endHour = parseInt(times.startHour) + 1
            setTimes(newTimes)
        }
    }

    useEffect(() => {
        updateTimes()
        
    }, [])

    useEffect(() => {
        updateTimesInForm()
    }, [times])

    useEffect(() => {
        if(formState.startTime === '' || formState.endTime === '') {
            setTimes(initialTimes)
        } 
    })

    return (
        <form onSubmit={handleSubmit} className='bookingForm'>
            <h4>Click on a day to start filling out info.</h4>
            <div className='personalInfo inputSection'>
                <div className='inputField'>
                    <input type='text'
                    id='name' 
                    value={formState.name}
                    onChange={updateInput}
                    placeholder='Name'
                    required
                    />
                </div>
                <div className='inputField'>
                    <input type='email'
                    id='email' 
                    value={formState.email}
                    onChange={updateInput}
                    placeholder='Email@email.com'
                    required
                    />
                </div>
                <div className='inputField'>
                    <input type='text'
                    id='phone' 
                    value={formState.topic}
                    onChange={updateInput}
                    placeholder='Phone #'
                    required
                    />
                </div>
                <div className='inputField otherInput'>
                    <select id='type' value={formState.type.substring(0, 5) === 'Other' ? 'Other' : formState.type} onChange={updateInput} required>
                        <option valie=''>Type of Event</option>
                        <option value='Wedding'>Wedding</option>
                        <option value='Corporate'>Corporate Event</option>
                        <option value='Private'>Private Event</option>
                        <option value='Festival'>Festival</option>
                        <option value='Other'>Other</option>
                    </select>
                </div>
                <div className='inputField typeOther'>
                    <input type='text'
                    id='type' 
                    value={typePlaceholder}
                    onChange={handleOtherChange}
                    placeholder='Type...'
                    className={formState.type.substring(0, 5) === 'Other' ? 'typeOtherInput visible' : 'typeOtherInput invisible'}
                    />
                </div>
                <div className='inputField'>
                    <input type='number'
                    id='guests' 
                    value={formState.guests}
                    onChange={updateInput}
                    placeholder='# of guests'
                    required
                    />
                </div>
            </div>
            <div className='time inputSection'>
                <div className='inputField'>
                    <input type='date'
                    id='date' 
                    value={formState.date}
                    onChange={updateInput}
                    placeholder='MM/DD/YYYY'
                    required
                    />
                </div>
                <h3>Start Time</h3>
                <div className='timeInput'>
                    <div className='inputField timeHour'>
                        <select id='startHour' value={times.startHour} onChange={handleTimeChange} required >
                            <option value=''className='timePlaceholder'>0:</option>
                            {
                                hours.map(number => {
                                    return (<><option value={number}>{number}:00</option>
                                        <option value={number + ':30'}>{number}:30</option></>)
                                })
                            }
                        </select>
                    </div>
                    <div className='inputField timeAMPM'>
                        <select id='startAMPM' value={times.startAMPM} onChange={handleTimeChange} required>
                            <option value='' className='timePlaceholder'>AM</option>
                            <option value='AM'>AM</option>
                            <option value='PM'>PM</option>
                        </select>
                    </div>
                </div>
                <h3>End Time</h3>
                <div className='timeInput'>
                    <div className='inputField timeHour'>
                        <select id='endHour' value={times.endHour} onChange={handleTimeChange} required>
                            <option value='' className='timePlaceholder'>0:</option>
                            {
                                hours.map(number => {
                                    return (<><option value={number}>{number}:00</option>
                                    <option value={number + ':30'}>{number}:30</option></>)
                                })
                            }
                        </select>
                    </div>
                    <div className='inputField timeAMPM'>
                        <select id='endAMPM' value={times.endAMPM} onChange={handleTimeChange} required>
                            <option value='' className='timePlaceholder'>AM</option>
                            <option value='AM'>AM</option>
                            <option value='PM'>PM</option>
                        </select>
                    </div>
                </div>
            </div>
            <div className='where inputSection'>
                <div className='inputField'>
                    <Map calculateDistance={calculateDistance} formState={formState} handleAutocompleteChange={handleAutocompleteChange} autocompleteValue={autocompleteValue} onPlaceChanged={onPlaceChanged} onLoad={onLoad}/>
                </div>
                <div className='inputField'>
                    <input type='text'
                    id='venue' 
                    value={formState.venue}
                    onChange={updateInput}
                    placeholder='Venue'
                    />
                </div>
                <div className='inputField'> 
                    <input type='text'
                    id='city' 
                    value={formState.city}
                    onChange={updateInput}
                    placeholder='City'
                    required
                    />
                </div>
                <div className='inputField'>
                    <input type='text'
                    id='state' 
                    value={formState.state}
                    onChange={updateInput}
                    placeholder='State'
                    required
                    />
                </div>
                <div className='inputField'>
                    <input type='text'
                    id='zip' 
                    value={formState.zip}
                    onChange={updateInput}
                    placeholder='Zip'
                    required
                    />
                </div>
                <div className='message'>
                    <textarea id='message' placeholder='Additional Comments' />
                </div>
                <h4>Travel Distance: {distance}</h4>
            </div> 
            <section className='recaptcha submit'>
                <ReCAPTCHA
                ref={recaptchaRef}
                sitekey={recaptchaKey}
                onChange={updateRecaptchaToken}
                />
                <input type='submit' className='submitBtn' disabled={submitting}/>
            </section>
        </form>
    )
}
